<template>
  <v-card class="rounded-10 pt-5" elevation="3" min-height="700">
    <v-card-text>
      <v-row dense align="stretch" justify="start" class="fill-height">
        <v-sheet class="col-md-8 col-sm-12" v-if="buildMode">
          <Builder min-height="500" ref="builder" v-model="questions">
            <template v-slot:header>
              <v-sheet
                dense
                :class="[
                  mdAndUp
                    ? 'justify-space-between'
                    : 'justify-center flex-column'
                ]"
                class="mb-5 d-flex align-center px-3"
              >
                <div class="my-1 d-flex align-center justify-start">
                  <h1 class="primary--text">Form Builder</h1>
                </div>
                <div class="my-1 d-flex align-center justify-end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        class="text-none ml-1 d-flex align-center justify-space-between"
                        color="primary"
                      >
                        <v-icon left>mdi-content-copy</v-icon> Copy Form
                        <v-icon right>mdi-menu-swap</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense max-height="400" style="overflow-y: auto">
                      <v-list-item v-if="!form_templates.length">
                        <v-list-item-title>
                          No available template yet
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-else
                        v-for="(item, index) in form_templates"
                        @click="questions = item.questions"
                        :key="index"
                      >
                        <v-list-item-title class="fw-700 primary--text">
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    @click="clearAll"
                    depressed
                    class="text-none ml-1"
                    color="primary"
                  >
                    <v-icon left>mdi-delete</v-icon> Clear All
                  </v-btn>
                </div>
              </v-sheet>
              <v-card outlined class="mb-3 rounded-tl-10 rounded-tr-10" flat>
                <v-card-title
                  :style="`background-color:${theme_color};`"
                  class="fullwidth d-flex align-center justify-space-between light--text"
                >
                  <ContentEditable
                    class="light--text fw-500 fs-25"
                    style="min-height: 10px; min-width: 100px"
                    v-model="title"
                  ></ContentEditable>
                </v-card-title>
                <v-card-text class="py-5">
                  <label class="primary--text">Description</label>
                  <v-textarea
                    class=""
                    rows="1"
                    v-model="description"
                    dense
                    placeholder="Enter form description"
                    outlined
                    auto-grow
                    hide-details="auto"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </template>
          </Builder>
        </v-sheet>
        <v-sheet
          :class="[buildMode ? `col-md-4 col-sm-12` : `col-md-12 col-sm-12`]"
        >
          <div v-if="mdAndUp" class="d-flex align-center justify-end fullwidth">
            <v-btn
              @click="buildMode = !buildMode"
              depressed
              v-if="buildMode"
              class="text-none ma-1"
              color="accent"
            >
              <v-icon left>mdi-eye</v-icon> <span>Preview Mode</span>
            </v-btn>
            <v-btn
              v-else
              @click="buildMode = !buildMode"
              depressed
              class="text-none ma-1"
              color="accent"
            >
              <v-icon left>mdi-pencil</v-icon> <span>Exit Preview Mode</span>
            </v-btn>
            <v-btn
              v-if="buildMode"
              depressed
              color="primary"
              class="ma-1 text-none"
              @click="goBack"
            >
              Back
            </v-btn>
          </div>
          <v-card v-if="buildMode" elevation="3" class="mt-10 rounded-5">
            <v-card-title class="align-center justify-center primary--text">
              <v-icon left color="primary" size="20">mdi-cog</v-icon>
              <strong>Form Settings</strong>
            </v-card-title>
            <v-card-text
              class="px-md-10 fullwidth d-flex align-center flex-column px-sm-5"
            >
              <div class="fullwidth mb-2">
                <label class="primary--text subtitle-2"> Theme Color </label>
                <v-card flat class="py-1">
                  <v-color-picker
                    dense
                    class="mx-auto"
                    mode="hexa"
                    v-model="theme_color"
                    canvas-height="50"
                  ></v-color-picker>
                </v-card>
              </div>
              <div class="fullwidth mb-2">
                <label class="primary--text subtitle-2"> Label Color </label>
                <v-card flat class="py-1">
                  <v-color-picker
                    dense
                    class="mx-auto"
                    mode="hexa"
                    v-model="label_color"
                    canvas-height="50"
                  ></v-color-picker>
                </v-card>
              </div>
              <div class="fullwidth mb-2">
                <div class="d-flex align-center justify-space-between">
                  <label class="primary--text subtitle-2">Company Logo </label>
                  <v-btn
                    small
                    class="text-none"
                    @click="show_company_logo_upload_modal = true"
                    color="primary"
                  >
                    Upload
                  </v-btn>
                </div>
                <v-card flat class="py-1">
                  <v-img
                    contain
                    max-height="100"
                    :src="company_logo || user.company.company_logo"
                  >
                  </v-img>
                </v-card>
              </div>
              <div class="fullwidth mb-2">
                <label class="primary--text subtitle-2">
                  Form Notifications
                  <HelpToolTip
                    icon-size="15"
                    icon-class="ml-2"
                    text="Will send response notifications to these emails when a
                      respond is made on this form.<br/> Email addresses separated by
                      comma"
                    icon="mdi-help-circle-outline"
                  ></HelpToolTip>
                </label>
                <v-text-field
                  outlined
                  dense
                  v-model="form_notif_receivers"
                  :rules="multipleEmailRules"
                  hide-details="auto"
                  placeholder="email1@example.com,email2@example.com"
                ></v-text-field>
              </div>
              <div class="fullwidth mb-2">
                <label class="primary--text subtitle-2">
                  Accepting Responses
                  <HelpToolTip
                    icon-size="15"
                    icon-class="ml-2"
                    text="Inactive form will no longer accept responses"
                    icon="mdi-help-circle-outline"
                  ></HelpToolTip>
                </label>
                <v-radio-group
                  class="my-1"
                  hide-details="auto"
                  row
                  mandatory
                  v-model="status"
                >
                  <v-radio value="active" label="Active"></v-radio>
                  <v-radio value="inactive" label="Inactive"></v-radio>
                </v-radio-group>
              </div>
              <div class="fullwidth mb-2">
                <label class="primary--text subtitle-2">
                  Login required?
                  <HelpToolTip
                    icon-size="15"
                    icon-class="ml-2"
                    text="Required respondent to be logged in before submitting
                      their response/answer"
                    icon="mdi-help-circle-outline"
                  ></HelpToolTip>
                </label>
                <v-radio-group
                  class="my-1"
                  hide-details="auto"
                  row
                  mandatory
                  v-model="required_auth"
                >
                  <v-radio value="yes" label="Yes"></v-radio>
                  <v-radio value="no" label="No"></v-radio>
                </v-radio-group>
              </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center py-5 align-center">
              <v-btn
                min-width="150"
                color="primary"
                depressed
                :disabled="!canSave"
                :loading="saving"
                @click="saveOrUpdate"
                class="text-none"
              >
                <span v-if="!isFormEdit">Save</span>
                <span v-else>Update</span>
              </v-btn>
              <!-- <v-btn
                min-width="100"
                color="primary"
                depressed
                :disabled="!canSave"
                :loading="saving"
                @click="saveOrUpdate"
                class="text-none"
              >
                <span>Preview</span>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-sheet>
        <v-sheet v-if="!buildMode" class="col-md-8 mx-auto col-sm-12">
          <v-list-item>
            <v-list-item-avatar width="80" height="80" color="grey" tile>
              <v-img contain v-if="company_logo" :src="company_logo" />
              <v-icon large v-else color="white">
                mdi-alpha-c-circle-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="headline primary--text font-weight-bold"
              >
                {{ title | ucwords }}
              </v-list-item-title>
              <v-list-item-subtitle class="primary--text">
                {{ user.company.name | ucwords }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-sheet class="my-1 text-center pa-1" v-if="description">
            <p>{{ description }}</p>
          </v-sheet>
          <Preview :questions="questions"></Preview>
        </v-sheet>
      </v-row>
    </v-card-text>
    <DeleteDialog
      v-model="confirm_update_dialog"
      title="Confirm update"
      delete-button-text="Yes, Update it!"
      text-content="This form has a mapping, updating this form will also delete those mapping. Are you sure you want to continue?"
      @delete="proceedUpdate"
    ></DeleteDialog>

    <ImageCropper
      value-format="attachment"
      v-model="show_company_logo_upload_modal"
      @file="handleImageUpload"
    ></ImageCropper>
  </v-card>
</template>

<script>
import _ from 'lodash'
import request from '@/services/axios_instance'
import Builder from './components/Builder/Builder.vue'
import ContentEditable from '@/common/Forms/ContentEditable.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { mapGetters, mapMutations } from 'vuex'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
import Preview from './components/Builder/Preview.vue'
export default {
  components: { Builder, ContentEditable, DeleteDialog, ImageCropper, Preview },
  data: () => ({
    title: 'Untitled Form',
    description: null,
    buildMode: true,
    status: 'active',
    form_notif_receivers: null,
    required_auth: 'no',
    theme_color: '#223a6b',
    label_color: '#223a6b',
    logo_attachment: null,
    company_logo: null,

    original_questions: [],
    form_templates: [],
    formToEdit: null,
    saving: false,
    confirm_update_dialog: false,
    show_company_logo_upload_modal: false
  }),
  watch: {
    theme_color: {
      handler: function (val) {
        this.set_theme_color(val)
      },
      immediate: true
    },
    label_color: {
      handler: function (val) {
        this.set_label_color(val)
      },
      immediate: true
    },
    '$route.params.id': {
      handler: function (val) {
        this.saving = false
        if (val && val > 0) this.getForm(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    questions: {
      get() {
        return this.$store.getters['forms/structures']
      },
      set(val) {
        this.set_structures(val)
      }
    },
    isFormEdit() {
      return this.formToEdit ? true : false
    },
    canSave() {
      if (this.questions.length === 0) return false
      if (
        !this.title ||
        this.title.trim().length === 0 ||
        this.title.trim() === '<br>'
      )
        return false
      return true
    },
    notif_email_receivers() {
      if (!this.form_notif_receivers) {
        return []
      }
      return this.form_notif_receivers.split(',')
    },
    payload() {
      return {
        title: this.title,
        description: this.description,
        questions: this.questions,
        status: this.status,
        required_auth: this.required_auth,
        theme_color: this.theme_color,
        label_color: this.label_color,
        notif_email_receivers: this.notif_email_receivers,
        attachment: this.logo_attachment
      }
    }
  },
  created() {
    this.getFormsTemplate()
    if (!this.isFormEdit && !this.company_logo) {
      this.company_logo = this.user.company
        ? this.user.company.company_logo
        : null
    }
  },
  methods: {
    ...mapMutations('forms', [
      'set_theme_color',
      'set_label_color',
      'set_structures'
    ]),
    clearAll() {
      if (this.isFormEdit) {
        this.appConfirmation(`Clear all form fields?`, () => {
          this.questions = []
        })
      } else this.questions = []
    },
    getFormsTemplate() {
      request.get(`api/forms/list`).then(({ data }) => {
        this.form_templates = data
      })
    },
    getForm(id) {
      request.get(`api/forms/${id}`).then(({ data }) => {
        if (data) {
          this.formToEdit = data
          this.status = data.status
          this.required_auth = data.props.required_auth || 'no'
          this.description = data.props.description || ''
          // this.questions = _.cloneDeep(data.questions)
          this.set_structures(data.questions)
          this.original_questions = _.cloneDeep(data.questions)
          this.title = data.title
          this.form_notif_receivers = null
          if (
            data.props.notif_email_receivers &&
            data.props.notif_email_receivers.length
          ) {
            this.form_notif_receivers =
              data.props.notif_email_receivers.join(',')
          }
          this.theme_color = data.props.theme_color || '#223a6b'
          this.label_color = data.props.label_color || '#223a6b'
          this.company_logo = data.company_logo
        }
      })
    },
    saveOrUpdate() {
      if (this.isFormEdit) {
        if (this.formToEdit.maps.length > 0) {
          this.confirm_update_dialog = true
        } else {
          this.proceedUpdate()
        }
      } else {
        this.save()
      }
    },
    save() {
      this.saving = true
      request
        .post(`api/forms`, this.payload)
        .then(({ data }) => {
          this.appSnackbar('Form successfully created!')
          this.$router.push({ name: 'forms' })
        })
        .finally(() => {
          this.saving = false
        })
    },
    proceedUpdate() {
      this.saving = true
      request
        .put(`api/forms/${this.formToEdit.id}`, this.payload)
        .then(({ data }) => {
          this.appSnackbar('Form successfully updated!')
          this.$router.push({ name: 'forms' })
        })
        .finally(() => {
          this.saving = false
          this.confirm_update_dialog = false
        })
    },
    goBack() {
      if (this.isFormEdit) {
        this.appConfirmation(
          `Changes made on this form are not yet save.<br> Would you like to discard changes?`,
          () => {
            if (
              this.$route.query.hasOwnProperty('redirect_uri') &&
              this.$route.query.redirect_uri
            ) {
              this.$router.push({ path: this.$route.query.redirect_uri })
            } else {
              this.$router.go(-1)
            }
          },
          null,
          'Yes discard changes'
        )
      } else {
        if (
          this.$route.query.hasOwnProperty('redirect_uri') &&
          this.$route.query.redirect_uri
        ) {
          this.$router.push({ path: this.$route.query.redirect_uri })
        } else {
          this.$router.go(-1)
        }
      }
    },
    handleImageUpload(file) {
      this.logo_attachment = file
      this.company_logo = file.url_inline
      this.show_company_logo_upload_modal = false
    },
    removeLogo() {
      this.logo_attachment = null
      this.company_logo = this.user.company.company_logo
    }
  }
}
</script>

<style></style>
